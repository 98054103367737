import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"
import { SearchBox } from "components/SearchBox"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import DepartmentRequestItems from "pages/AdminApp/InventoryRequest/DepartmentRequestItems"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { InventoryReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import customStyles from "pages/Common/AutoCompleteStyles"
import { DateRangePicker } from "components/DatePicker"
import {
   GetDepartmentInventoryRequestListing,
   GetDepartmentsListing,
   // DepartmentFulfillmentByWarehouse,
} from "api/api.service"
import Config from "Config"
import { Refresh } from "components/Refresh"
import EditIcon from "../../../assets/images/icons/edit-icon.svg"

const COLUMNS = {
   SLOT_ID: "Slot Id",
   TRAILER_TYPE: "Trailer Type",
   DATE_ADDED: "Date Added",
   ESTIMATED_DATE: "Estimated Date",
   PRIORITY: "Priority",
   STATUS: "Status",
   ACTION: "Action",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const AllTrailers = () => {
   let controller = new AbortController()
   let signal = controller.signal
   const [isSubmitting, setIsSubmitting] = useState(false)
   const [selectedData, setSelectedData] = useState({})
   const [statusFilters, setStatusFilters] = useState(null)
   const [departmentsListing, setDepartmentsListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      isDataAvailable: true,
   })
   const [modalToggle, setModalToggle] = useState(false)
   const [departmentsFilter, setDepartmentsFilter] = useState(null)

   const {
      data,
      page,
      pageSize,
      total,
      isFetching,
      searchText,
      request,
      handleSearchChange,
      handlePageClick,
      onChangePageSize,
   } = useLocalPaginatedRequest({
      requestFn: GetDepartmentInventoryRequestListing,
      params: {
         ...(statusFilters?.value && {
            requestStatus: statusFilters?.value,
         }),
         ...(departmentsFilter?.length && {
            departmentId: departmentsFilter.map(item => Number(item.value)),
         }),
      },
      deps: [statusFilters, departmentsFilter],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   useEffect(() => {
      handleDepartmentsListing(departmentsListing.page)

      return () => {
         controller.abort()
      }
   }, [departmentsListing.Q])

   const handleSelectDepartments = option => {
      if (typeof option != "object" || !option) return
      setDepartmentsFilter(option)
   }
   const handleDepartmentSearch = (value = "", callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   const handleDepartmentsListing = page => {
      setDepartmentsListing(prev => {
         return {
            ...prev,
            fetching: true,
         }
      })

      GetDepartmentsListing(
         {
            Limit: Config.LIMIT,
            ...(departmentsListing.Q && { Q: departmentsListing.Q }),
            Page: page,
         },
         signal
      )
         .then(res => {
            setDepartmentsListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length == Config.LIMIT,
               data: (() => {
                  let test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: item.departmentName,
                  }))

                  return [...test]
               })(),
            }))
         })
         .finally(() => {
            setDepartmentsListing(prev => {
               return {
                  ...prev,
                  fetching: false,
               }
            })
         })
   }

   const statusSelectOptions = [
      {
         label: "Open",
         value: "Open",
      },
      {
         label: "In Progress",
         value: "InProgress",
      },
      {
         label: "Completed",
         value: "Completed",
      },
   ]

   const handleModalToggle = data => {
      setSelectedData(data || {})
      setModalToggle(prev => !prev)
   }
   const handleSelectStatusOption = option => {
      setStatusFilters(option)
   }

   return (
      <>
         <Card className="mb-5">
            <Refresh onClick={request} />
            <CardBody className="p-2 p-md-4">
               <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between admin-inventory-request-form-fields ">
                     <div className="d-flex justify-content-start align-items-center admin-inventory-request-autocomplete">
                        <div className="min-250-w margin-top-handle">
                           <AutoComplete
                              value={statusFilters}
                              isClearable
                              onChange={val => handleSelectStatusOption(val)}
                              isSearchable={false}
                              placeholder="Select Status"
                              customStyles={customStyles.AdminAppSelectStyles}
                              classNamePrefix="status-header-search-admin"
                              options={statusSelectOptions}
                           />
                        </div>
                        <div className="mx-2 min-250-w d-flex">
                           <DateRangePicker />
                        </div>
                        <div className="min-250-w margin-right-handle me-2">
                           <AutoComplete
                              isMulti
                              placeholder="Select Production Line"
                              value={departmentsFilter}
                              customStyles={customStyles.AdminAppSelectStyles}
                              handleBlur={() =>
                                 setDepartmentsListing(prev => {
                                    return {
                                       ...prev,
                                       page: 1,
                                       Q: "",
                                    }
                                 })
                              }
                              onInputChange={val =>
                                 handleDepartmentSearch(
                                    val,
                                    setDepartmentsListing
                                 )
                              }
                              options={departmentsListing.data}
                              onChange={val => handleSelectDepartments(val)}
                              closeMenuOnSelect={true}
                              isLoading={departmentsListing?.fetching}
                              classNamePrefix="status-header-search-admin"
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <DataTable
                  data={data}
                  loading={isFetching}
                  tableClasses="ad-inventory-request-table"
                  config={[
                     {
                        title: COLUMNS.SLOT_ID,
                        render: data => {
                           return (
                              <div className="highlighted-col">{data.id}</div>
                           )
                        },
                     },
                     {
                        title: COLUMNS.TRAILER_TYPE,
                        render: data => data?.departmentName,
                     },
                     {
                        title: COLUMNS.DATE_ADDED,
                        render: data =>
                           convertDateTime({
                              date: data.createdAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           }),
                     },
                     {
                        title: COLUMNS.ESTIMATED_DATE,
                        render: data =>
                           convertDateTime({
                              date: data.createdAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           }),
                     },
                     {
                        title: COLUMNS.PRIORITY,
                        render: data => data?.departmentName,
                     },
                     {
                        title: COLUMNS.STATUS,
                        render: data => {
                           return (
                              <div
                                 className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                                    data.status
                                 )}`}
                              >
                                 {InventoryReqStatus.getDisplayTextKey(
                                    data.status
                                 )}
                              </div>
                           )
                        },
                     },
                     {
                        className: "text-center",
                        title: COLUMNS.ACTION,
                        render: data => {
                           return (
                              <>
                                 <span
                                    className="text-success pointer"
                                    onClick={() => handleModalToggle(data)}
                                 >
                                    <img src={ActionEyeIcon} />
                                 </span>
                                 <span
                                    className=" pointer ms-3"
                                    onClick={() => editUserHandler(data)}
                                 >
                                    <img src={EditIcon} />
                                 </span>
                              </>
                           )
                        },
                     },
                  ]}
               />
               <Pagination
                  currentPage={page}
                  totalData={total}
                  onPageClick={handlePageClick}
                  rowLimit={pageSize}
               />
            </CardBody>
         </Card>
      </>
   )
}

export default AllTrailers
