import { format } from "date-fns"
import { enUS } from "date-fns/locale" // Import locale
import React, { useState } from "react"
import { DateRange } from "react-date-range"
import calenderIcon from "../../assets/images/icons/calender.png"
import { dateTimeFormat } from "utils/dateFormatUtils"

const DateRangePicker = () => {
   const [range, setRange] = useState([
      {
         startDate: new Date(),
         endDate: new Date(),
         key: "selection",
      },
   ])
   const [isOpen, setIsOpen] = useState(false)

   const handleSelect = ranges => {
      setRange([ranges.selection])
      // setIsOpen(false)
   }

   const formatDateRange = range => {
      if (!range?.[0]) return ""

      const startDate = range[0]?.startDate
         ? format(new Date(range[0].startDate), dateTimeFormat.dateRangeFormat)
         : ""
      const endDate = range[0]?.endDate
         ? format(new Date(range[0].endDate), dateTimeFormat.dateRangeFormat)
         : ""

      return startDate && endDate
         ? `${startDate} - ${endDate}`
         : startDate || endDate
   }
   return (
      <div className="date-range-wrapper align-items-center">
         <div className="input-container">
            <input
               type="text"
               readOnly
               placeholder="Select Date Range"
               value={formatDateRange(range)}
               className="input-field"
               onClick={() => setIsOpen(!isOpen)}
            />
            <img
               src={calenderIcon}
               alt="Calendar Icon"
               className="input-icon"
            />
         </div>

         {isOpen && (
            <DateRange
               ranges={range}
               onChange={handleSelect}
               moveRangeOnFirstSelection={true}
               rangeColors={["#0060bd"]}
               months={2}
               showSelectionPreview={true}
               locale={enUS} // Set the locale explicitly
               showDateDisplay={false}
               showMonthArrow={true}
               direction="horizontal"
               showMonthAndYearPickers={true}
               retainEndDateOnFirstSelection={true}
               className="date-range-picker"
            />
         )}
      </div>
   )
}

export default DateRangePicker
