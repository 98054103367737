import React from "react"

const Stages = ({ stagesData }) => {
   return (
      <div className="stages">
         <div className="stages-heading">Stages</div>
         {stagesData.map((stage, index) => (
            <div key={index} className="stages-stage">
               <div className="stages-stage-stagename trim-text">
                  {stage.stageName}
               </div>
               <div className="stages-stage-station">
                  <p className="trim-text-2">{stage.station}</p>
               </div>
            </div>
         ))}
      </div>
   )
}

export default Stages
