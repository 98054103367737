import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"

import { getRoute } from "helpers/routeHelpers"
import { NavTabsRoutingBasedButtons as NavButtons } from "pages/AdminApp/Common/NavTabsRoutingBased"
import { getMatchingTabKey } from "utils/commonUtils"
const DEFAULT_ROUTE = 0;
const TabRoute = ({ tabConfig, routesConfig }) => {
   const history = useHistory()

   const ROUTES_BASED_KEYS = tabConfig.reduce((acc, { path, identifier }) => {
      acc[getRoute(path)] = identifier
      return acc
   }, {})

   const [isActive, setIsActive] = useState({
      ...(getMatchingTabKey(history?.location?.pathname, ROUTES_BASED_KEYS)
         ? {
              [ROUTES_BASED_KEYS[
                 getMatchingTabKey(
                    history?.location?.pathname,
                    ROUTES_BASED_KEYS
                 )
              ]]: true,
           }
         : {}),
   })

   useEffect(() => {
      const TABS_KEYS = tabConfig.map(tab => [tab.identifier, false])
      setIsActive({
         ...Object.fromEntries(TABS_KEYS),
         ...(getMatchingTabKey(history?.location?.pathname, ROUTES_BASED_KEYS)
            ? {
                 [ROUTES_BASED_KEYS[
                    getMatchingTabKey(
                       history?.location?.pathname,
                       ROUTES_BASED_KEYS
                    )
                 ]]: true,
              }
            : {}),
      })
   }, [history.location.pathname, tabConfig])

   const TABS_CONFIG = tabConfig.map(tab => ({
      title: tab.title,
      onClick: () => {
         const TABS_KEYS = tabConfig.map(tab => [tab.identifier, false])
         setIsActive({
            ...Object.fromEntries(TABS_KEYS),
            [tab.identifier]: true,
         })
         history.push(tab.path)
      },
      classesButton: "al-nav-link",
      identifier: tab.identifier,
      icon: tab.icon,
      activeIcon: tab.activeIcon,
   }))

   return (
      <>
         <Row>
            <Col className="col-12">
               <div className="admin-location mb-3 mt-4">
                  <div className="al-navtabs-main">
                     <div className="al-nav-tabs">
                        <div
                           className="mx-2 al-nav-item d-flex flex-wrap justify-content-start"
                           style={{ gap: "clamp(0rem, 1vw, 1.25rem)" }}
                        >
                           {" "}
                           <NavButtons
                              data={TABS_CONFIG}
                              classes={"ps-1 mx-0 flex-wrap d-inline-block "}
                              isActive={isActive}
                           />
                        </div>
                     </div>
                  </div>
               </div>

               <Switch>
                  {routesConfig.map(({ name, exact, path, component }, idx) => (
                     <Route
                        key={idx}
                        name={name}
                        exact={exact}
                        path={path}
                        component={component}
                     />
                  ))}
                  <Redirect to={tabConfig[DEFAULT_ROUTE].path} />
               </Switch>
            </Col>
         </Row>
      </>
   )
}

export default TabRoute
