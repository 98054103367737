import * as Yup from "yup"

export const FIELDS_NAME = {
   TRAILER_TYPE: "Trailer Type",
   PRIORITY: "Priority",
   NEXT_AVAILABLE_SLOT: "Next Available Slot",
}

export const initialValues = {
   [FIELDS_NAME.PRIORITY]: "",
   [FIELDS_NAME.TRAILER_TYPE]: "",
   [FIELDS_NAME.NEXT_AVAILABLE_SLOT]: "",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.TRAILER_TYPE]: Yup.object()
      .nullable()
      .required("Select Trailer Type"),
   [FIELDS_NAME.PRIORITY]: Yup.object().nullable().required("Select Priority"),
   [FIELDS_NAME.NEXT_AVAILABLE_SLOT]: Yup.object()
      .nullable()
      .required("Select Available Slot"),
})
