import React, { useEffect, useState } from "react"

import { GetDepartmentsListing } from "api/api.service"
import { AutoComplete } from "components/AutoComplete"
import { DateRangePicker } from "components/DatePicker"
import Config from "Config"
import customStyles from "pages/Common/AutoCompleteStyles"
import ProductionScheduleCalendar from "./ProductionScheduleCalendar"
import { Button } from "components/Button"

import emptyIcon from "../../../assets/images/schedule/production-empty.svg"

const ProductionSchedule = () => {
   let controller = new AbortController()
   let signal = controller.signal
   const [departmentsFilter, setDepartmentsFilter] = useState(null)
   const [departmentsListing, setDepartmentsListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      isDataAvailable: true,
   })

   useEffect(() => {
      handleDepartmentsListing(departmentsListing.page)

      return () => {
         controller.abort()
      }
   }, [departmentsListing.Q])

   const handleSelectDepartments = option => {
      if (typeof option != "object" || !option) return
      setDepartmentsFilter(option)
   }
   const handleDepartmentSearch = (value = "", callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }
   const handleDepartmentsListing = page => {
      setDepartmentsListing(prev => {
         return {
            ...prev,
            fetching: true,
         }
      })

      GetDepartmentsListing(
         {
            Limit: Config.LIMIT,
            ...(departmentsListing.Q && { Q: departmentsListing.Q }),
            Page: page,
         },
         signal
      )
         .then(res => {
            setDepartmentsListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length == Config.LIMIT,
               data: (() => {
                  let test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: item.departmentName,
                  }))

                  return [...test]
               })(),
            }))
         })
         .finally(() => {
            setDepartmentsListing(prev => {
               return {
                  ...prev,
                  fetching: false,
               }
            })
         })
   }

   return (
      <>
         {" "}
         <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between admin-inventory-request-form-fields ">
               <div className="d-flex justify-content-between w-100 align-items-center admin-inventory-request-autocomplete">
                  <div className="d-flex justify-content-start align-items-center">
                     <div className="mx-2 min-250-w d-flex">
                        <DateRangePicker />
                     </div>
                     <div className="min-250-w margin-right-handle me-2">
                        <AutoComplete
                           isMulti
                           placeholder="Select Production Line"
                           value={departmentsFilter}
                           customStyles={customStyles.AdminAppSelectStyles}
                           handleBlur={() =>
                              setDepartmentsListing(prev => {
                                 return {
                                    ...prev,
                                    page: 1,
                                    Q: "",
                                 }
                              })
                           }
                           onInputChange={val =>
                              handleDepartmentSearch(val, setDepartmentsListing)
                           }
                           options={departmentsListing.data}
                           onChange={val => handleSelectDepartments(val)}
                           closeMenuOnSelect={true}
                           isLoading={departmentsListing?.fetching}
                           classNamePrefix="status-header-search-admin"
                        />
                     </div>
                  </div>
                  <Button
                     title={
                        <div className="d-flex gap-2 align-items-center">
                           {" "}
                           <img
                              alt="slot-card-icon"
                              src={emptyIcon}
                              width={15}
                              height={15}
                           />{" "}
                           <p>Show Next Inventory Warning</p>
                        </div>
                     }
                     className={"production-button"}
                     onClick={() => {}}
                  />
               </div>
            </div>
         </div>
         <ProductionScheduleCalendar />
      </>
   )
}

export default ProductionSchedule
