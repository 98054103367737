import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { Col, Form, Row } from "reactstrap"

import {
   CreateWarranty,
   GetWarrantyDetails,
   UpdateWarranty,
} from "api/api.service"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import FormikErrorText from "components/FormikErrorText/FormikErrorText"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"
import { Modal } from "components/Modal"
import { AutoCompleteStyling } from "pages/Common/AutoCompleteStyling"
import { FIELDS_NAME, initialValues, validationSchema } from "../../FormConfig"

const TITLES = {
   MODAL_ADD_TITLE: "Create Order",
   ADD_BTN_TEXT: "Create",
}
const TRAILER_TYPE = [
   { value: 5, label: 5 },
   { value: 7, label: 7 },
]
const PRIORITY = [
   { value: 5, label: 5 },
   { value: 7, label: 7 },
]
const NEXT_AVAILABLE_SLOT = [
   { value: 5, label: 5 },
   { value: 7, label: 7 },
]

const handleUpdateExecution = async (id, data) => {
   let res = await UpdateWarranty(id, data)
   toast.success("Warranty has been updated successfully !")
}

const AddTrailerModal = ({
   isDetailView = false,
   isOpen = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const { trailerId } = useParams()
   const [isLoading, setIsLoading] = useState(false)
   const [isFetching, setIsFetching] = useState(false)

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      let bodyData = {
         configuratorTrailerId: Number(trailerId),
         name: values[FIELDS_NAME.NAME],
         price: Number(values[FIELDS_NAME.PRICE]),
         year: Number(values[FIELDS_NAME.YEAR].value),
         status: values[FIELDS_NAME.STATUS],
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const handleUpdate = async data => {
      const payload = {
         ...data,
      }

      try {
         setIsLoading(true)
         await handleUpdateExecution(id, payload)
         handleModalClose()
         onSuccess()
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      try {
         setIsLoading(true)
         let res = await CreateWarranty(data)
         toast.success("Order has been created successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetWarrantyDetails(id)

         formik.setValues({
            [FIELDS_NAME.TRAILER_TYPE]: res?.name,
            [FIELDS_NAME.PRIORITY]: res?.price,
            [FIELDS_NAME.NEXT_AVAILABLE_SLOT]: res.status,
         })
      } finally {
         setIsFetching(false)
      }
   }

   const handleModalClose = () => {
      formik.resetForm()
      onToggleModal()
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container justify-content-center mb-0"
         sizeClasses="5"
         backdrop="static"
         modalTitle={id ? TITLES.MODAL_UPDATE_TITLE : TITLES.MODAL_ADD_TITLE}
      >
         {isFetching ? (
            <div>
               <FormSkeleton />
            </div>
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Form onSubmit={formik.handleSubmit}>
                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Trailer Type
                           </label>
                           <div className="col-md-9">
                              <AutoComplete
                                 name={FIELDS_NAME.TRAILER_TYPE}
                                 placeholder="Select Trailer Type"
                                 classNamePrefix="add-new-req"
                                 options={TRAILER_TYPE}
                                 customStyles={AutoCompleteStyling}
                                 value={formik.values[FIELDS_NAME.TRAILER_TYPE]}
                                 onChange={option =>
                                    formik.setFieldValue(
                                       FIELDS_NAME.TRAILER_TYPE,
                                       option || ""
                                    )
                                 }
                                 onBlur={() =>
                                    formik.setFieldTouched(
                                       FIELDS_NAME.TRAILER_TYPE,
                                       true
                                    )
                                 }
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.TRAILER_TYPE}
                              />
                           </div>
                        </Row>
                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Priority:
                           </label>
                           <div className="col-md-9">
                              <AutoComplete
                                 name={FIELDS_NAME.PRIORITY}
                                 placeholder="Select priority"
                                 classNamePrefix="add-new-req"
                                 options={PRIORITY}
                                 customStyles={AutoCompleteStyling}
                                 value={formik.values[FIELDS_NAME.PRIORITY]}
                                 onChange={option =>
                                    formik.setFieldValue(
                                       FIELDS_NAME.PRIORITY,
                                       option || ""
                                    )
                                 }
                                 onBlur={() =>
                                    formik.setFieldTouched(
                                       FIELDS_NAME.PRIORITY,
                                       true
                                    )
                                 }
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.PRIORITY}
                              />
                           </div>
                        </Row>
                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Next Available Slot:
                           </label>
                           <div className="col-md-9">
                              <AutoComplete
                                 name={FIELDS_NAME.NEXT_AVAILABLE_SLOT}
                                 placeholder="Select next available slot"
                                 classNamePrefix="add-new-req"
                                 options={NEXT_AVAILABLE_SLOT}
                                 customStyles={AutoCompleteStyling}
                                 value={
                                    formik.values[
                                       FIELDS_NAME.NEXT_AVAILABLE_SLOT
                                    ]
                                 }
                                 onChange={option =>
                                    formik.setFieldValue(
                                       FIELDS_NAME.NEXT_AVAILABLE_SLOT,
                                       option || ""
                                    )
                                 }
                                 onBlur={() =>
                                    formik.setFieldTouched(
                                       FIELDS_NAME.NEXT_AVAILABLE_SLOT,
                                       true
                                    )
                                 }
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.NEXT_AVAILABLE_SLOT}
                              />
                           </div>
                        </Row>
                        <div className="d-flex justify-content-center mt-3 mt-2">
                           <Button
                              isLoading={isLoading}
                              type={"submit"}
                              title={
                                 id
                                    ? TITLES.UPDATE_BTN_TEXT
                                    : TITLES.ADD_BTN_TEXT
                              }
                              className="header-button"
                              onClick={isDetailView && handleModalClose}
                           />
                        </div>
                     </Form>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default AddTrailerModal
