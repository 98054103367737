import React from "react"
import classNames from "classnames"

import emptyIcon from "../../../assets/images/schedule/production-empty.svg"
import incompleteIcon from "../../../assets/images/schedule/production-incomplete.svg"
import completeIcon from "../../../assets/images/schedule/production-complete.svg"

const SlotCard = props => {
   const {
      clientName = "",
      orderNumber = "",
      trailerType = "",
      icon = undefined,
      isSelected = false,
      onClick = () => {},
   } = props

   const getIcon = () => {
      if (icon === "empty") return emptyIcon
      if (icon === "incomplete") return incompleteIcon
      if (icon === "complete") return completeIcon
      return null
   }

   return (
      <div
         className={classNames("slot-card", {
            "slot-card-selected": isSelected,
         })}
         onClick={onClick}
      >
         <p className="slot-card-client trim-text">{clientName}</p>
         <p className="slot-card-order trim-text">{orderNumber}</p>
         <p className="slot-card-trailer trim-text">{trailerType}</p>
         {getIcon() && (
            <img alt="slot-card-icon" src={getIcon()} width={15} height={15} />
         )}
      </div>
   )
}

export default SlotCard
