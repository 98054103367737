import React from "react"
import ProductionDayGrid from "./ProductionDayGrid"
import { Stages } from "."

const stagesData = [
   { stageName: "Mechanical", station: "Frames 1" },
   { stageName: "Mechanical", station: "Frames 2" },
   { stageName: "Paint", station: "PAINT TRLR FRMS PULL T & B" },
   { stageName: "Paint", station: "PAINT TRLR FRMS PULL T & B" },
   { stageName: "Paint", station: "PAINT TRLR FRMS PULL T & B" },
   { stageName: "Paint", station: "PAINT TRLR FRMS PULL T & B" },
]
const ProductionScheduleCalendar = () => {
   return (
      <div className="production-schedule">
         <Stages stagesData={stagesData} />
         <div style={{ display: "flex", overflowX: "auto" }}>
            <ProductionDayGrid isOverride={true} />
            <ProductionDayGrid />
            <ProductionDayGrid isOverride={true} />
            <ProductionDayGrid />
         </div>
      </div>
   )
}

export default ProductionScheduleCalendar
