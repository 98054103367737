import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { SlotCard } from "."
import InspectionNoteModal from "./InspectionNoteModal"

const ProductionDayGrid = props => {
   const { date = "Monday 4/26/2021", isOverride = false } = props

   const [isModalOpen, setIsModalOpen] = useState(false)
   const [textAreaValue, setTextAreaValue] = useState("")
   const [isChecked, setIsChecked] = useState(false)

   const toggleModal = () => setIsModalOpen(!isModalOpen)

   const handleSave = () => {
      console.log("Saved Data:", { textAreaValue, isChecked })
      toggleModal()
   }

   const slots = [
      {
         clientName: "WELTON-CP",
         orderNumber: "16952",
         trailerType: "17 SPI DLX",
         icon: "empty",
      },
      {
         clientName: "Rawley - hc",
         orderNumber: "16953",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Lua - jw",
         orderNumber: "16954",
         trailerType: "17 Liberty Dlx",
         isSelected: true,
      },
      {
         clientName: "Toney - Vv",
         orderNumber: "16955",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Gibbs - hc",
         orderNumber: "16957",
         trailerType: "17 SPI DLX",
         icon: "incomplete",
      },
      {
         clientName: "Holle - Vv",
         orderNumber: "16950",
         trailerType: "17 SPI DLX",
         isSelected: true,
      },
      {},
      {
         clientName: "WELTON-CP",
         orderNumber: "16955",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Rawley - hc",
         orderNumber: "16954",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Toney - Vv",
         orderNumber: "16952",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Lua - jw",
         orderNumber: "16953",
         trailerType: "17 SPI DLX",
         icon: "incomplete",
         isSelected: true,
      },
      {
         clientName: "Gibbs - hc",
         orderNumber: "16954",
         trailerType: "17 SPI DLX",
         icon: "complete",
      },
      {},
      {},
      {
         clientName: "Lua - jw",
         orderNumber: "16953",
         trailerType: "17 SPI DLX",
         icon: "incomplete",
      },
      {
         clientName: "Gibbs - hc",
         orderNumber: "16954",
         trailerType: "17 SPI DLX",
         icon: "complete",
      },
      {
         clientName: "Lua - jw",
         orderNumber: "16954",
         trailerType: "17 Liberty Dlx",
         isSelected: true,
      },
      {
         clientName: "Toney - Vv",
         orderNumber: "16955",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Gibbs - hc",
         orderNumber: "16957",
         trailerType: "17 SPI DLX",
         icon: "incomplete",
      },
      {},
      {},
      {},
      {
         clientName: "Rawley - hc",
         orderNumber: "16954",
         trailerType: "17 SPI DLX",
      },
      {
         clientName: "Toney - Vv",
         orderNumber: "16952",
         trailerType: "17 SPI DLX",
      },
   ]

   return (
      <div className="production-day">
         <div className="production-day-date">{date}</div>
         <div className="production-day-override">
            {isOverride ? "Override" : ""}
         </div>
         <Row className="mb-0 production-day-slot-type">
            <Col xs={3} className="production-day-slot-type-premium">
               Premium
            </Col>
            <Col xs={9} className="production-day-slot-type-regular">
               Regular
            </Col>
         </Row>
         <Row className="production-day-row">
            {slots.map((slot, idx) => (
               <Col xs={3} className="production-day-row-column" key={idx}>
                  <SlotCard {...slot} index={idx} onClick={toggleModal} />
               </Col>
            ))}
         </Row>

         {isModalOpen && (
            <InspectionNoteModal
               isOpen={isModalOpen}
               toggle={toggleModal}
               textAreaValue={textAreaValue}
               setTextAreaValue={setTextAreaValue}
               isChecked={isChecked}
               setIsChecked={setIsChecked}
               handleSave={handleSave}
            />
         )}
      </div>
   )
}

export default ProductionDayGrid
