import React from "react"
import {
   Modal,
   ModalHeader,
   ModalBody,
   Button,
   Input,
   FormGroup,
   Label,
   Row,
   Col,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

const InspectionNoteModal = ({
   isOpen,
   toggle,
   handleSave,
   initialValues = { textAreaValue: "", isChecked: false },
}) => {
   const validation = useFormik({
      initialValues,
      validationSchema: Yup.object({
         textAreaValue: Yup.string().trim().required("Details are required"),
      }),
      onSubmit: values => {
         handleSave(values)
         toggle()
      },
   })

   return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
         <ModalHeader toggle={toggle}>Inspection Note</ModalHeader>
         <ModalBody>
            <form onSubmit={validation.handleSubmit}>
               <Row className="">
                  <Col xs={12}>
                     <FormGroup>
                        {/* <Label for="textArea">Details</Label> */}
                        <Input
                           type="textarea"
                           id="textArea"
                           name="textAreaValue"
                           value={validation.values.textAreaValue}
                           onChange={validation.handleChange}
                           onBlur={validation.handleBlur}
                           invalid={
                              validation.touched.textAreaValue &&
                              !!validation.errors.textAreaValue
                           }
                        />
                        {validation.touched.textAreaValue &&
                           validation.errors.textAreaValue && (
                              <div className="form-input-error">
                                 {validation.errors.textAreaValue}
                              </div>
                           )}
                     </FormGroup>
                  </Col>
                  <Col xs={9} className="d-flex align-items-center">
                     <FormGroup check>
                        <Label check>
                           <Input
                              type="checkbox"
                              name="isChecked"
                              checked={validation.values.isChecked}
                              onChange={validation.handleChange}
                           />{" "}
                           Check to confirm
                        </Label>
                     </FormGroup>
                  </Col>
                  <Col xs={3}>
                     <Button type="submit" color="primary" block>
                        Save
                     </Button>
                  </Col>
               </Row>
            </form>
         </ModalBody>
      </Modal>
   )
}

export default InspectionNoteModal
