import { APP_ROUTES } from "helpers/routeHelpers"

import dashboardIcon from "../../assets/images/icons/dashboard-icon.svg"
import locationIcon from "../../assets/images/icons/ad-location-icon.svg"
import adminInvreq from "../../assets/images/icons/ad-inventory-request.svg"
import adminUserMang from "../../assets/images/icons/ad-user-management.svg"
import invMangIcon from "../../assets/images/icons/inventory-management-icon.svg"
import orderMangIcon from "../../assets/images/icons/order-management-icon.svg"
import venMangIcon from "../../assets/images/icons/vendor-management-icon.svg"
import settingsIcon from "../../assets/images/icons/wh-sd-settings-icon-wt.svg"
import trailerMngIcon from "../../assets/images/icons/trailer-management-icon.svg"
import timelineIcon from "../../assets/images/icons/timeline-icons.png"
import countsheetIcon from "../../assets/images/icons/countsheet-icon.svg"
import { UserRoles } from "../../constants"

const { Finance, Executive, SuperAdmin, Admin } = UserRoles

export const sideBarContent = [
   {
      routeName: APP_ROUTES.DASHBOARD,
      title: "Dashboard",
      image: dashboardIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      routeName: APP_ROUTES.LOCATION,
      title: "Locations",
      image: locationIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Executive, SuperAdmin],
   },

   {
      routeName: APP_ROUTES.INVENTORY_REQUEST,
      title: "Inventory Requests",
      image: adminInvreq,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Executive, SuperAdmin],
   },
   {
      routeName: APP_ROUTES.INVENTORY_MANAGEMENT,
      title: "Inventory Management",
      image: invMangIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      routeName: APP_ROUTES.ORDER_MANAGEMENT,
      title: " Order Management",
      image: orderMangIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      routeName: APP_ROUTES.USER_MANAGEMENT,
      title: " User Management",
      image: adminUserMang,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [SuperAdmin],
   },

   {
      routeName: APP_ROUTES.VENDOR_MANAGEMENT,
      title: " Vendor Management",
      image: venMangIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },

   {
      routeName: APP_ROUTES.CONFIGURATOR_TRAILERS,
      title: "Configurator Settings",
      image: settingsIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [SuperAdmin],
   },
   {
      routeName: APP_ROUTES.TRAILERS_MANAGEMENT,
      title: " Trailer  Management",
      image: trailerMngIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [SuperAdmin],
   },
   {
      routeName: APP_ROUTES.TIMELINE,
      title: "Timeline",
      image: timelineIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },
   {
      routeName: APP_ROUTES.ORDERED_TRAILERS,
      title: "Configurator Orders",
      image: orderMangIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [SuperAdmin],
   },
   {
      routeName: APP_ROUTES.COUNT_SHEET,
      title: "Countsheets",
      image: countsheetIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Executive, SuperAdmin],
   },

   {
      routeName: APP_ROUTES.SETTINGS,
      title: " Settings",
      image: settingsIcon,
      imageClasses: "",
      titleClasses: "",
      routeClasses: "",
      liClasses: "",
      permissions: [Finance, Executive, SuperAdmin],
   },

   // {
   //   routeName: APP_ROUTES.FAQS,
   //   title: "FAQ'S",
   //   image: quesCircleIcon,
   //   imageClasses: "",
   //   titleClasses: "",
   //   routeClasses: "",
   //   liClasses: "",
   // },
]
