import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"

import { APP_ROUTES } from "helpers/routeHelpers"
import { Header } from "pages/AdminApp/Common/Header"
import { AllTrailers } from "./AllTrailers"
import { AddTrailerModal } from "./Modals"
import { ProductionSchedule } from "./ProductionSchedule"

import TabRoute from "components/TabRoutes/TabRoute"
import allTrailersInactiveIcon from "../../assets/images/icons/Union-disable.png"
import allTrailersActiveIcon from "../../assets/images/icons/Union.png"
import inactiveProduction from "../../assets/images/icons/Vector-disbale.png"
import activeProduction from "../../assets/images/icons/Vector.png"

const TABS_KEYS = {
   AllTrailersTab: "allTrailers",
   ProductionScheduleTab: "productionSchedule",
}
const TABS_NAME = {
   AllTrailers: "All Trailers",
   ProductionSchedule: "Production Schedule",
}

const ROUTES_CONFIG = [
   {
      name: TABS_NAME.AllTrailers,
      exact: true,
      component: AllTrailers,
      path: APP_ROUTES.TIMELINE_ALL_TRAILERS,
   },
   {
      name: TABS_NAME.ProductionSchedule,
      exact: true,
      component: ProductionSchedule,
      path: APP_ROUTES.TIMELINE_PRODUCTION_SCHEDULE,
   },
]

const TABS_CONFIG = [
   {
      title: TABS_NAME.AllTrailers,
      identifier: TABS_KEYS.AllTrailersTab,
      icon: allTrailersInactiveIcon,
      activeIcon: allTrailersActiveIcon,
      path: APP_ROUTES.TIMELINE_ALL_TRAILERS,
   },

   {
      title: TABS_NAME.ProductionSchedule,
      identifier: TABS_KEYS.ProductionScheduleTab,
      icon: inactiveProduction,
      activeIcon: activeProduction,
      path: APP_ROUTES.TIMELINE_PRODUCTION_SCHEDULE,
   },
]
const TimelineManagement = () => {
   const history = useHistory()
   const [selection, setSelection] = useState({})
   const [isTrailerModalOpen, setIsTrailerModalOpen] = useState(false)

   const handleAddTrailer = () => {
      setIsTrailerModalOpen(!isTrailerModalOpen)
   }

   return (
      <>
         <Row>
            <Col className="col-12">
               <Header
                  headerTitle="Timeline"
                  btnTitle={
                     !!isActive.productionSchedule
                        ? "Create Order"
                        : "Add Trailer "
                  }
                  onClick={handleAddTrailer}
                  // onChangeHandler={handleSearchChange}
                  // searchText={searchText}
                  headerBtnResponsive="ad-header-btn-responsive"
                  searchResponsiveness="admin-user-mangement-search-responsiveness"
                  flexResponsive="ad-user-mangement"
                  flexResponsiveInner="ad-user-management-inner"
                  SearchBoxContainer="ad-search-box-container"
                  // currentSortOrder={currentSortOrder}
                  // currentSortColumn={currentSortColumn}
                  // onSortClick={handleSortClick}
                  showSearch={!isActive.productionSchedule}
               />
            </Col>
         </Row>
         {true && (
            <AddTrailerModal
               isDetailView={false}
               isOpen={isTrailerModalOpen}
               id={selection?.id}
               //  onSuccess={request}
               onToggleModal={() => {
                  setSelection({})
                  setIsTrailerModalOpen(prev => !prev)
               }}
            />
         )}

         <TabRoute tabConfig={TABS_CONFIG} routesConfig={ROUTES_CONFIG} />
      </>
   )
}

export default TimelineManagement
