import React, { useEffect, useState } from "react"
import { Col, Row, Breadcrumb, BreadcrumbItem } from "reactstrap"
import {
   Switch,
   Route,
   useHistory,
   Redirect,
   useParams,
} from "react-router-dom"

import baseFeaturesInactiveIcon from "../../../assets/images/icons/base-features-inactive-icon.png"
import baseFeaturesActiveIcon from "../../../assets/images/icons/base-features-active-icon.png"
import packagesInactiveIcon from "../../../assets/images/icons/packages-inactive-icon.png"
import packagesActiveIcon from "../../../assets/images/icons/packages-active-icon.png"
import customizationsInactiveIcon from "../../../assets/images/icons/customization-inactive-icon.png"
import customizationsActiveIcon from "../../../assets/images/icons/customization-active-icon.png"
import equipmentInactiveIcon from "../../../assets/images/icons/equipment-inactive-icon.png"
import equipmentActiveIcon from "../../../assets/images/icons/equipment-active-icon.png"
import summaryInactiveIcon from "../../../assets/images/icons/summary-inactive-icon.png"
import summaryActiveIcon from "../../../assets/images/icons/summary-active-icon.png"
import activeWarrantyIcon from "../../../assets/images/icons/active-warranty.png"
import inactiveWarrantyIcon from "../../../assets/images/icons/inactive-warranty.png"
import { NavTabsRoutingBasedButtons as NavButtons } from "../../AdminApp/Common/NavTabsRoutingBased"
import { BaseFeatures, BaseFeaturesCategoryItems } from "../BaseFeatures"
import { Packages } from "../Packages"
import { Customization, CustomizationCategoryItems } from "../Customization"
import { Equipment, EquipmentCategoryItems } from "../Equipment"
import { Summary } from "../Summary"
import { Build, BuildStationItems } from "../Build"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { GetTrailersDetails } from "api/api.service"
import { TrailerSize } from "../../../constants"
import { PackageItems } from "../Packages"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Warranty } from "../Warranty"
import { getMatchingTabKey } from "utils/commonUtils"

const BREADCRUMB_NAME = {
   configurator: "Configurator",
   trailer: "Trailer",
   build: "Build",
   "base-features": "Base features",
   items: "Items",
   personalization: "Personalization",
   packages: "Packages",
   equipment: "Equipment",
   "base-settings": "Base settings",
   "sales-marketing": "Sales & marketing",
   "station-items": "Items",
   summary: "Summary",
   warranty: "Warranty",
}

const TABS_KEYS = {
   BuildTab: "buildTab",
   BaseFeatureTab: "baseFeaturesTab",
   PackagesTab: "packagesTab",
   CustomizationTab: "customizationTab",
   EquipmentTab: "equipmentTab",
   WarrantyTab: "WarrantyTab",
   SummaryTab: "summaryTab",
}

const TABS_NAME = {
   Build: "Build",
   BaseFeatures: "Base Features",
   Packages: "Packages",
   Customization: "Personalization",
   Equipment: "Equipment",
   Warranty: "Warranty",
   Summary: "Summary",
}

const TABS_STATUS = {
   [TABS_KEYS.BuildTab]: false,
   [TABS_KEYS.BaseFeatureTab]: false,
   [TABS_KEYS.PackagesTab]: false,
   [TABS_KEYS.CustomizationTab]: false,
   [TABS_KEYS.EquipmentTab]: false,
   [TABS_KEYS.SummaryTab]: false,
   [TABS_KEYS.WarrantyTab]: false,
}

const ROUTES_CONFIG = [
   {
      name: TABS_NAME.Build,
      title: "Build Configuration",
      exact: true,
      component: Build,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD,
   },
   {
      name: TABS_NAME.BaseFeatures,
      title: "Base Features",
      exact: true,
      component: BaseFeatures,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES,
   },
   {
      name: TABS_NAME.Packages,
      title: "Package Selection",
      exact: true,
      component: Packages,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES,
   },
   {
      name: TABS_NAME.Customization,
      title: "Customization Options",
      exact: true,
      component: Customization,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION,
   },
   {
      name: null,
      title: "Customization Category Items",
      exact: false,
      component: CustomizationCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION_ITEMS,
   },
   {
      name: TABS_NAME.Equipment,
      title: "Equipment Configuration",
      exact: true,
      component: Equipment,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS,
   },
   {
      name: null,
      title: "Equipment Category Items",
      exact: true,
      component: EquipmentCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENT_ITEMS,
   },
   {
      name: TABS_NAME.Summary,
      title: "Summary",
      exact: false,
      component: Summary,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY,
   },
   {
      name: null,
      title: "Base Features Category Items",
      exact: true,
      component: BaseFeaturesCategoryItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES_ITEMS,
   },
   {
      name: null,
      title: "Build Station Items",
      exact: true,
      component: BuildStationItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD_STATION_ITEMS,
   },
   {
      name: null,
      title: "Package Items",
      exact: true,
      component: PackageItems,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES_ITEMS,
   },
   {
      name: TABS_NAME.Warranty,
      title: "Warranty",
      exact: true,
      component: Warranty,
      path: APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES_WARRANTY,
   },
]

const TrailerSettings = () => {
   const history = useHistory()
   const { trailerId } = useParams()
   const trailerIdParamObj = { trailerId: trailerId }

   const ROUTES_BASED_KEYS = {
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.BuildTab,

      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.BaseFeatureTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.PackagesTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES_WARRANTY, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.WarrantyTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.CustomizationTab,
      [getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS, {
         ...trailerIdParamObj,
      })]: TABS_KEYS.EquipmentTab,
      [getRoute(
         APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY_BASE_SETTINGS,
         {
            ...trailerIdParamObj,
         }
      )]: TABS_KEYS.SummaryTab,
   }

   const [isActive, setIsActive] = useState({
      ...TABS_STATUS,
      ...(getMatchingTabKey(history?.location?.pathname, ROUTES_BASED_KEYS)
         ? {
              [ROUTES_BASED_KEYS[
                 getMatchingTabKey(
                    history?.location?.pathname,
                    ROUTES_BASED_KEYS
                 )
              ]]: true,
           }
         : {}),
   })

   const [trailerDetails, setTrailerDetails] = useState({})
   const [isFetching, setIsFetching] = useState(false)

   useEffect(() => {
      handleGetTrailerDetails()
   }, [trailerId])

   const TABS_CONFIG = [
      {
         title: TABS_NAME.Build,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               buildTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.BuildTab,
         icon: baseFeaturesInactiveIcon,
         activeIcon: baseFeaturesActiveIcon,
      },

      {
         title: TABS_NAME.BaseFeatures,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               baseFeaturesTab: true,
            })
            history.push(
               getRoute(
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES,
                  {
                     ...trailerIdParamObj,
                  }
               )
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.BaseFeatureTab,
         icon: baseFeaturesInactiveIcon,
         activeIcon: baseFeaturesActiveIcon,
      },
      {
         title: TABS_NAME.Customization,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               customizationTab: true,
            })
            history.push(
               getRoute(
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION,
                  {
                     ...trailerIdParamObj,
                  }
               )
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.CustomizationTab,
         icon: customizationsInactiveIcon,
         activeIcon: customizationsActiveIcon,
      },

      {
         title: TABS_NAME.Packages,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               packagesTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.PackagesTab,
         icon: packagesInactiveIcon,
         activeIcon: packagesActiveIcon,
      },

      {
         title: TABS_NAME.Equipment,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               equipmentTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.EquipmentTab,
         icon: equipmentInactiveIcon,
         activeIcon: equipmentActiveIcon,
      },
      {
         title: TABS_NAME.Warranty,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               WarrantyTab: true,
            })
            history.push(
               getRoute(
                  APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_PACKAGES_WARRANTY,
                  {
                     ...trailerIdParamObj,
                  }
               )
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.WarrantyTab,
         icon: inactiveWarrantyIcon,
         activeIcon: activeWarrantyIcon,
      },

      {
         title: TABS_NAME.Summary,
         onClick: () => {
            setIsActive({
               ...TABS_STATUS,
               summaryTab: true,
            })
            history.push(
               getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_SUMMARY, {
                  ...trailerIdParamObj,
               })
            )
         },
         classesButton: "al-nav-link",
         identifier: TABS_KEYS.SummaryTab,
         icon: summaryInactiveIcon,
         activeIcon: summaryActiveIcon,
      },
   ]

   const handleGetTrailerDetails = async () => {
      try {
         setIsFetching(true)
         let res = await GetTrailersDetails(trailerId)
         setTrailerDetails(res)
      } catch (error) {
      } finally {
         setIsFetching(false)
      }
   }

   const makeTrailerName = obj => {
      return isFetching
         ? "...."
         : `${TrailerSize.getDisplayTextKey(obj.configuratorTrailerSizeType)} ${
              obj?.configuratorTrailerType
           } ${obj?.model}`
   }

   const generateBreadcrumbs = () => {
      const pathnames = history.location.pathname.split("/").filter(x => x)
      return (
         <small>
            <Breadcrumb>
               {pathnames.map((value, index) => {
                  const to = `/${pathnames.slice(0, index + 1).join("/")}`

                  if (Number(value) || value == "trailer" || value == "items") {
                     return ""
                  }

                  return (
                     <BreadcrumbItem
                        key={to}
                        active={index === pathnames.length - 1}
                     >
                        <Link
                           to={{
                              pathname:
                                 value == "configurator"
                                    ? APP_ROUTES.CONFIGURATOR_TRAILERS
                                    : to,
                           }}
                        >
                           {BREADCRUMB_NAME[value]
                              ? BREADCRUMB_NAME[value]
                              : value}
                        </Link>
                     </BreadcrumbItem>
                  )
               })}
            </Breadcrumb>
         </small>
      )
   }

   return (
      <>
         <Row>
            <Col className="col-12">
               <div className="d-flex align-items-baseline">
                  <h3>Configurator Settings</h3>
               </div>
               <div className="admin-location mb-3 mt-4">
                  <div className="al-navtabs-main">
                     <div className="al-nav-tabs ">
                        <div
                           className="mx-2 al-nav-item d-flex flex-wrap justify-content-start"
                           style={{ gap: "clamp(0rem, 1vw, 1.25rem)" }}
                        >
                           {" "}
                           <NavButtons
                              data={TABS_CONFIG}
                              classes={"ps-1 mx-0 flex-wrap d-inline-block "}
                              isActive={isActive}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               {generateBreadcrumbs()}

               <Switch>
                  {ROUTES_CONFIG.map(
                     ({ name, exact, path, component }, idx) => (
                        <Route
                           key={idx}
                           name={name}
                           exact={exact}
                           path={path}
                           component={component}
                        />
                     )
                  )}
                  <Redirect
                     to={APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES}
                  />
               </Switch>
            </Col>
         </Row>
      </>
   )
}

export default TrailerSettings
